<template>
  <r-e-dialog title="房间核验" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="14vh"
              :width="width" @click-submit="clickSubmitTType" @click-cancel="newclickCancel" @close="newclickCancel"
              :footerBtnName="footerBtnNameList">
    <div>
      <el-form :model="ruleForm" :rules="rulesradio" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="请选择核验方式" prop="resource">
          <el-radio-group v-model="ruleForm.resource" @input="inputresource">
            <el-radio label="房间核验"></el-radio>
            <el-radio label="核验码核验"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="ruleForm.resource === '房间核验'">

      <el-steps :active="active" simple>
        <el-step title="核验" icon="el-icon-edit"></el-step>
        <el-step title="提交" icon="el-icon-upload"></el-step>
      </el-steps>

      <div style="height: 20px;"></div>
      <div v-if="active===1">
        <el-form ref="formPublish" label-width="120px" size="small" :model="formPublish" :rules="rulesoen">
          <div class="flex">
            <el-form-item label="房间核验码">
              <el-input v-model="formPublish.apartmentCode" style="width: 290px;"/>
            </el-form-item>
            <el-form-item label="房间所属公司">
              <el-input v-model="formPublish.rightHolderName" style="width: 290px;"/>
            </el-form-item>
            <el-form-item label="所属公司证件">
              <el-input v-model="formPublish.rightHolderId" style="width: 290px;"/>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="权证号" prop="warrantNo">
              <el-input v-model="formPublish.warrantNo" style="width: 290px;"/>
            </el-form-item>
            <el-form-item label="地址">
              <el-input v-model="formPublish.address" style="width: 290px;"/>
            </el-form-item>
            <el-form-item label="经纪人姓名">
              <el-input v-model="formPublish.publisher" style="width: 290px;"/>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="经纪人身份证">
              <el-input v-model="formPublish.brokerID" style="width: 290px;"/>
            </el-form-item>
            <el-form-item label="中介机构名称">
              <el-input v-model="formPublish.intermediaryName" style="width: 290px;"/>
            </el-form-item>
            <el-form-item label="证件类型">
              <el-select v-model="formPublish.warrantType" placeholder="请选择" style="width: 290px;">
                <el-option label="房屋所有权证号或不动产权证号" value="1"/>
                <el-option label="商品房网签合同编号或预售合同备案编号" value="2"/>
                <el-option label="租赁备案证明号或使用权证号" value="3"/>
                <el-option label="其他" value="4"/>
              </el-select>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="">
              <el-button type="primary" @click="active=2" size="small" icon="el-icon-search">重新核验</el-button>
            </el-form-item>
          </div>
          <div class="flex" v-if="formPublish.status === 1">
            <el-form-item label="">
              <el-button type="primary" @click="getRecordArtiCheckResult" size="small" icon="el-icon-search">
                核验结果
              </el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div v-if="active===2">
        <el-form ref="rightHolderInfoVo" label-width="160px" size="small" :model="rightHolderInfoVo" :rules="rules">
          <div class="flex">
            <el-form-item label="权利人姓名">
              <el-input v-model="rightHolderInfoVo.rightHolderName" style="width: 250px;"/>
            </el-form-item>
            <el-form-item label="权利人证件类型">
              <el-select v-model="rightHolderInfoVo.certificateType" style="width: 250px;" placeholder="请选择">
                <el-option label="居民身份证" value="居民身份证"/>
                <el-option label="事业单位法人证书" value="事业单位法人证书"/>
                <el-option label="港澳台通行证" value="港澳台通行证"/>
                <el-option label="港澳台身份证" value="港澳台身份证"/>
                <el-option label="往来大陆通行证" value="往来大陆通行证"/>
                <el-option label="护照" value="护照"/>
                <el-option label="户口簿" value="户口簿"/>
                <el-option label="军官证（士兵证）" value="军官证（士兵证）"/>
                <el-option label="组织机构代码证" value="组织机构代码证"/>
              </el-select>
            </el-form-item>
            <el-form-item label="权利人证件号码">
              <el-input v-model="rightHolderInfoVo.rightHolderID" style="width: 250px;"/>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="权利人联系电话">
              <el-input v-model="rightHolderInfoVo.phone" style="width: 250px;"/>
            </el-form-item>
            <el-form-item label="权利类型">
              <el-select v-model="rightHolderInfoVo.rightType" style="width: 250px;" placeholder="请选择">
                <el-option label="所有权人" value="所有权人"/>
                <el-option label="使用权人" value="使用权人"/>
              </el-select>
            </el-form-item>
            <el-form-item label="权利人类型">
              <el-select v-model="rightHolderInfoVo.rightHolderType" style="width: 250px;" placeholder="请选择">
                <el-option label="个人" value="个人"/>
                <el-option label="单位" value="单位"/>
              </el-select>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="房屋有效权利证明类别">
              <el-select v-model="rightHolderInfoVo.warrantType" style="width: 250px;" placeholder="请选择">
                <el-option label="房屋所有权证号或不动产权证号" value="1"/>
                <el-option label="商品房网签合同编号或预售合同备案编号" value="2"/>
                <el-option label="租赁备案证明号或使用权证号" value="3"/>
                <el-option label="其他" value="4"/>
              </el-select>
            </el-form-item>
            <el-form-item label="权证号" >
              <el-input v-model="rightHolderInfoVo.warrantNo" style="width: 250px;"/>
            </el-form-item>
          </div>
        </el-form>

        <el-form ref="houseReqDataVo" label-width="160px" size="small" :model="houseReqDataVo" :rules="rules">
          <div class="flex">
            <el-form-item label="地址">
              <el-input v-model="houseReqDataVo.location" style="width: 250px;"/>
            </el-form-item>
            <el-form-item label="核验业务类型">
              <el-select v-model="houseReqDataVo.businessType" style="width: 250px;" placeholder="请选择">
                <el-option label="租赁房" value="0"/>
                <el-option label="二手房" value="1"/>
              </el-select>
            </el-form-item>
            <el-form-item label="中介公司名称">
              <el-input v-model="houseReqDataVo.intermediaryName" style="width: 250px;"/>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item label="负责人名称">
              <el-input v-model="houseReqDataVo.brokerName" style="width: 250px;"/>
            </el-form-item>
            <el-form-item label="负责人身份证号">
              <el-input v-model="houseReqDataVo.brokerID" style="width: 250px;"/>
            </el-form-item>
            <el-form-item label="申请标记">
              <el-select v-model="houseReqDataVo.applyStatus" style="width: 250px;" placeholder="请选择">
                <el-option label="首次申请" value="0"/>
                <el-option label="业务回退后再申请" value="1"/>
              </el-select>
            </el-form-item>
          </div>
        </el-form>

        <el-form ref="cdata" label-width="160px" size="small" :model="cdata" :rules="rules">
          <div class="flex">
            <el-form-item label="房型">
              <el-input v-model="cdata.comment" style="width: 250px;"/>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="addFile" size="small">添加文件</el-button>
            </el-form-item>
          </div>
        </el-form>

        <div v-for="(item,index) in fileUuidList" :key="index">
          <el-form :ref="'Scan'+index" label-width="160px" size="small" :model="fileUuidList[index]"
                   :rules="rules">
            <div class="flex">
              <el-form-item label="文件">
                <div style="width: 250px;">
                  <upload-avatar @on-success="(data)=>handleUploadSuccess(data,index)"
                                 :uuid="fileUuidList[index].scanData"/>
                </div>
              </el-form-item>
              <el-form-item label="文件名称">
                <el-input v-model="fileUuidList[index].scanName" style="width: 250px;"/>
              </el-form-item>
              <el-form-item label="">
                <el-button type="primary" @click="removeFile(index)" size="small">删除文件</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div style="font-size: 16px;color: red;font-weight: bold" v-if="msg">
          提示: {{ msg }}
        </div>
      </div>
    </div>
    <div v-if="ruleForm.resource === '核验码核验'">
      <el-form ref="formPublishapartmentCode" label-width="120px" size="small" :model="formPublishapartmentCode" :rules="rules">
        <div class="flex">
          <el-form-item label="房源核验码">
            <el-input v-model="formPublishapartmentCode.housingCode" style="width:195px;"/>
          </el-form-item>
          <el-form-item label="经纪人姓名">
            <el-input v-model="formPublishapartmentCode.brokerName" style="width: 195px;"/>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="经纪人身份证">
            <el-input v-model="formPublishapartmentCode.brokerID" style="width: 195px;"/>
          </el-form-item>
          <el-form-item label="中介机构名称">
            <el-input v-model="formPublishapartmentCode.intermediaryName" style="width: 195px;"/>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="权证号">
            <el-input v-model="formPublishapartmentCode.warrantNo" style="width: 195px;"/>
          </el-form-item>
        </div>。
      </el-form>
    </div>
  </r-e-dialog>
</template>

<script>
import {
  getRecordInfo, getRecordHouseInfo, getRecordHouseAutoCheck, getRecordArtiCheckResult
} from "@/api/house-resource";
import {getHousingCodeInfo} from "@/api/house-resource";
import uploadAvatar from "@/components/Upload/upload-avatar";
import {MessageSuccess, MessageError} from "@custom/message";

export default {
  name: "dialog-verification",
  data() {
    return {
      formPublishapartmentCode: {
        housingCode: null,
        brokerName: null,
        brokerID: null,
        intermediaryName: null,
        uuid: null,
        warrantNo:null,
      },
      // loadingOptions: {
      //   lock: true,
      //   text: "Loading...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // },
      // 上面的是核验玛校验集成过来的数据
      width:'1300px',
      dialogVisible: false,
      active: 1,
      footerBtnNameList:['取消','核验信息'],
      ruleForm: {
        resource: '房间核验',
      },
      formPublish: {
        address: null,
        apartmentCode: null,
        apartmentName: null,
        apartmentUuid: null,
        brokerID: null,
        businessNo: null,
        code: null,
        comment: null,
        communityName: null,
        district: null,
        erWeiMaUrl: null,
        insideArea: null,
        intermediaryName: null,
        isLetOutRent: null,
        monthAmount: null,
        outsideArea: null,
        publisher: null,
        publisherPhone: null,
        qrCodeUrl: null,
        qycode: null,
        qycodeUrl: null,
        qyqrCodeUrl: null,
        rentCommon: null,
        rightHolderId: null,
        rightHolderName: null,
        serviceNumber: null,
        staffCodeUrl: null,
        staffQrCode: null,
        staffQrCodeUrl: null,
        status: null,
        uuid: null,
        warrantNo: null,
        warrantType: null,
      },
      rules: {
        warrantNo:[{ required: true, message: '请输入活动名称', trigger: 'blur' }]
      },
      rulesoen:{
        warrantNo:[{ required: true, message: '请输入权证号', trigger: 'blur' }]
      },
      rulesradio: {
        resource: [
          {required: true, message: '请选择活动资源', trigger: 'change'}
        ],
      },
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      fileUuidList: [
        {scanName: null, scanData: null}
      ],
      rightHolderInfoVo: {
        rightHolderName: null,
        warrantNo: null,
        certificateType: null,
        rightHolderID: null,
        phone: null,
        warrantType: null,
        rightType: null,
        rightHolderType: null,
      },
      houseReqDataVo: {
        location: null,
        brokerID: null,
        brokerName: null,
        intermediaryName: null,
        businessType: null,
        applyStatus: null,
        businessNo: null,
      },
      cdata: {
        comment: null,
      },
      msg: null,
    };
  },
  props: {
    currentHouse: {
      type: Object,
      default: () => ({})
    }
  },
  components: {uploadAvatar},
  methods: {
    // 切换核验方式事件
    inputresource() {
      console.log(this.ruleForm)

      if(this.ruleForm.resource === '房间核验') this.width = '1300px'
      if(this.ruleForm.resource === '核验码核验') this.width = '700px'
    },
    //打开房间核验弹窗方法
    openDialog() {
      //初始化核验进度
      this.active = 1;
      //解构房间uuid
      const {apartmentUuid: uuid} = this.currentHouse;
      this.formPublishapartmentCode.uuid = uuid;
      //调用查询房间备案信息方法
      this.getRecordInfo(uuid);
    },
    //点击确认进行区分是哪个确认
    clickSubmitTType(){
      if(this.ruleForm.resource === '房间核验')this.clickSubmitHome()
      if(this.ruleForm.resource === '核验码核验')this.clickSubmit()
    },
    //点击确认按钮事件
    clickSubmitHome() {
      let that = this;
      //获取核验进度
      const {active} = that;
      //进度等于1，查询核验信息
      if (active === 1) {
        //表单校验
        this.$refs["formPublish"].validate((valid) => {
          //调用查询核验信息方法
          that.getRecordHouseInfo();
        });
      }
      //进度等于1，发起住建局房间核验
      if (active === 2) {
        //调用发起住建局房间核验方法
        that.getRecordHouseAutoCheck();
      }
    },
    // 点击取消判断调取哪一个取消
    newclickCancel(){
      if(this.ruleForm.resource === '房间核验')this.clickCancel()
      if(this.ruleForm.resource === '核验码核验')this.clickCancelHesuan()
    },
    // 核验码核验点击确认按钮事件
    clickSubmit() {
      let that = this;
      //表单校验
      this.$refs["formPublishapartmentCode"].validate((valid) => {
        if (valid) {
          //解构数据
          const {housingCode, brokerName, brokerID, intermediaryName, uuid,warrantNo} = that.formPublishapartmentCode;
          const loadingOptions = that.loadingOptions;
          const loading = that.$loading({...loadingOptions});
          // 调用接口核验码核验
          getHousingCodeInfo({housingCode, brokerName, brokerID, intermediaryName, uuid ,warrantNo}).then(res => {
            //解构返回数据
            const {msg} = res;
            //字符串转对象后解构返回参数
            const {result} = JSON.parse(msg);
            //解构返回状态码
            let {statusCode, statusMsg} = result;
            //转换code为数字
            statusCode = Number(statusCode);
            //判断statusCode是否大于0，大于0表示报错
            if (statusCode > 0) {
              MessageError(statusMsg, 15000);
            } else {
              // 解构返回数据
              let {
                businessStatus, housingCode, businessNo, street, districtName, buildingArea,
                mortgaged, limited, onRent, seize, securityHousing, decrepitHouse, leaseRecord,
              } = result;
              //字段值类型转换
              decrepitHouse = decrepitHouse !== null && decrepitHouse !== '' ? Number(decrepitHouse) : null;
              mortgaged = mortgaged !== null && mortgaged !== '' ? Number(mortgaged) : null;
              limited = limited !== null && limited !== '' ? Number(limited) : null;
              onRent = onRent !== null && onRent !== '' ? Number(onRent) : null;
              seize = seize !== null && seize !== '' ? Number(seize) : null;
              securityHousing = securityHousing !== null && securityHousing !== '' ? Number(securityHousing) : null;
              leaseRecord = leaseRecord !== null && leaseRecord !== '' ? Number(leaseRecord) : null;

              //根据值的数据，获取实际展示数据
              const mortgagedName = mortgaged !== null ? (mortgaged === 0 ? "无抵押" : "有抵押") : null;
              const limitedName = limited !== null ? (limited === 0 ? "无限制" : "有限制") : null;
              const onRentName = onRent !== null ? (onRent === 0 ? "未租赁" : "已租赁") : null;
              const seizedName = seize !== null ? (seize === 0 ? "未查封" : "查封") : null;
              const securityHousingName = securityHousing !== null ? (securityHousing === 0 ? "否" : "是") : null;
              const leaseRecordName = leaseRecord !== null ? (leaseRecord === 0 ? "否" : "是") : null;
              let decrepitHouseName = null;
              if (decrepitHouse !== null) {
                switch (decrepitHouse) {
                  case 0:
                    decrepitHouseName = "否";
                    break;
                  case 1:
                    decrepitHouseName = "A级 无危险";
                    break;
                  case 2:
                    decrepitHouseName = "B级 无危险";
                    break;
                  case 3:
                    decrepitHouseName = "C级 潜在危险";
                    break;
                  case 4:
                    decrepitHouseName = "D级 危险";
                    break;
                }
              }
              //创建动态元素
              const h = that.$createElement;
              let arr = [];
              arr.push(h('div', null, ' 申请状态:' + (businessStatus || "无") + ''));
              arr.push(h('div', null, ' 房源核验码:' + (housingCode || "无") + ''));
              arr.push(h('div', null, ' 核验业务编号:' + (businessNo || "无") + ''));
              arr.push(h('div', null, ' 街道:' + (street || "无") + ''));
              arr.push(h('div', null, ' 房屋所在城区:' + (districtName || "无") + ''));
              arr.push(h('div', null, ' 建筑面积:' + (buildingArea || "无") + ''));
              arr.push(h('div', null, ' 是否有抵押:' + (mortgagedName || "无") + ''));
              arr.push(h('div', null, ' 是否有限制:' + (limitedName || "无") + ''));
              arr.push(h('div', null, ' 是否在租 :' + (onRentName || "无") + ''));
              arr.push(h('div', null, ' 是否查封:' + (seizedName || "无") + ''));
              arr.push(h('div', null, ' 是否保障房:' + (securityHousingName || "无") + ''));
              arr.push(h('div', null, ' 是否危房 :' + (decrepitHouseName || "无") + ''));
              arr.push(h('div', null, ' 是否租赁备案:' + (leaseRecordName || "无") + ''));
              //提示
              this.$confirm(h('div', null, arr), '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: ''
              }).then(() => {
                //调用取消按钮事件，关闭弹框
                that.clickCancel();
              }).catch(() => {
                //调用取消按钮事件，关闭弹框
                that.clickCancel();
              });
            }
          }).finally(() => loading.close());
        }
      });
    },
    //点击取消按钮事件
    clickCancel() {
      //清空表单数据
      this.formPublish = {
        address: null, apartmentCode: null, apartmentName: null, apartmentUuid: null, brokerID: null,
        businessNo: null, code: null, comment: null, communityName: null, district: null, erWeiMaUrl: null,
        insideArea: null, intermediaryName: null, isLetOutRent: null, monthAmount: null, outsideArea: null,
        publisher: null, publisherPhone: null, qrCodeUrl: null, qycode: null, qycodeUrl: null,
        qyqrCodeUrl: null, rentCommon: null, rightHolderId: null, rightHolderName: null, serviceNumber: null,
        staffCodeUrl: null, staffQrCode: null, staffQrCodeUrl: null, status: null, uuid: null, warrantNo: null,
        warrantType: null,
      };
      this.fileUuidList = [{scanName: null, scanData: null}];
      this.rightHolderInfoVo = {
        rightHolderName: null, warrantNo: null, certificateType: null, rightHolderID: null, phone: null,
        warrantType: null, rightType: null, rightHolderType: null,
      };
      this.houseReqDataVo = {
        location: null, brokerID: null, brokerName: null, intermediaryName: null, businessType: null,
        applyStatus: null, businessNo: null,
      };
      this.cdata = {comment: null}
      //关闭弹框
      this.dialogVisible = false;
      //清空提示信息
      this.msg = null;
    },
    //核验码核验 点击取消按钮事件
    clickCancelHesuan() {
      this.formPublishapartmentCode = {
        housingCode: null, brokerName: null, brokerID: null, intermediaryName: null, uuid: null
      };
      this.dialogVisible = false;
    },
    //查询房间备案信息方法
    getRecordInfo(uuid) {
      let that = this;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //调用接口查询房间备案信息
      getRecordInfo({uuid}).then(res => {
        //保存旧数据
        const oldFormPublish = that.formPublish;
        //解构数据
        const {info} = res;
        //解构核验码
        const {businessNo} = info;
        //拆分数据并赋值
        that.formPublish = {...oldFormPublish, ...info};
        //核验码保存
        that.houseReqDataVo.businessNo = businessNo;
        //打开弹框
        that.dialogVisible = true;
      }).finally(() => loading.close());
    },
    //查询核验信息方法
    getRecordHouseInfo() {
      let that = this;
      //解构数据并重命名
      const {
        apartmentCode: housingCode, rightHolderName: rightHolderName, rightHolderId: rightHolderID,
        warrantNo: warrantNO, address: location, publisher: brokerName, brokerID,
        intermediaryName: intermediaryName, warrantType,
      } = that.formPublish;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //打包数据
      const data = {
        housingCode, rightHolderName, rightHolderID, warrantNO, location, brokerName, brokerID, warrantType,
        intermediaryName,uuid:that.formPublish.uuid
      }
      //调用接口查询核验信息
      getRecordHouseInfo(data).then(res => {

        console.log(res)
        //解构数据字符串
        const {msg} = res;
        //字符串转对象后解构返回参数
        const {result} = JSON.parse(msg);
        //解构返回状态码
        let {statusCode, statusMsg} = result;
        //转换statusCode为数字
        statusCode = Number(statusCode);
        //判断statusCode是否大于0，大于0表示报错
        if (statusCode > 0) {
          MessageError(statusMsg, 15000);
          that.active = 2;
        } else {
          //解构其他参数
          let {
            housingCode, districtName, location, projectName, street, houseNo, buildingNo, roomNo,
            houseStructure, sharedArea, buildingArea, insideArea, housingUse, startFloor, terminationFloor,
            countyCode, realEstateUnitNo, buildingTotalFloor, upperFloorNum, undergroundFloorNum,
            elevator, houseType, householdStructure, houseNature,
          } = result;
          //创建动态元素
          const h = that.$createElement;
          let arr = [];
          arr.push(h('div', null, ' 房屋编号:' + (housingCode || "无") + ''));
          arr.push(h('div', null, ' 房屋所在城区:' + (districtName || "无") + ''));
          arr.push(h('div', null, ' 房屋坐落:' + (location || "无") + ''));
          arr.push(h('div', null, ' 项目名称:' + (projectName || "无") + ''));
          arr.push(h('div', null, ' 街道:' + (street || "无") + ''));
          arr.push(h('div', null, ' 院门牌号:' + (houseNo || "无") + ''));
          arr.push(h('div', null, ' 栋号:' + (buildingNo || "无") + ''));
          arr.push(h('div', null, ' 室号:' + (roomNo || "无") + ''));
          arr.push(h('div', null, ' 结构:' + (houseStructure || "无") + ''));
          arr.push(h('div', null, ' 公摊面积:' + (sharedArea || "无") + ''));
          arr.push(h('div', null, ' 建筑面积:' + (buildingArea || "无") + ''));
          arr.push(h('div', null, ' 套内面积:' + (insideArea || "无") + ''));
          arr.push(h('div', null, ' 房屋用途:' + (housingUse || "无") + ''));
          arr.push(h('div', null, ' 起始层:' + (startFloor || "无") + ''));
          arr.push(h('div', null, ' 终止层:' + (terminationFloor || "无") + ''));
          arr.push(h('div', null, ' 房屋县代码:' + (countyCode || "无") + ''));
          arr.push(h('div', null, ' 不动产单元号:' + (realEstateUnitNo || "无") + ''));
          arr.push(h('div', null, ' 建筑总层数:' + (buildingTotalFloor || "无") + ''));
          arr.push(h('div', null, ' 地上层数:' + (upperFloorNum || "无") + ''));
          arr.push(h('div', null, ' 地下层数:' + (undergroundFloorNum || "无") + ''));
          arr.push(h('div', null, ' 有无电梯:' + (elevator || "无") + ''));
          arr.push(h('div', null, ' 户型:' + (houseType || "无") + ''));
          arr.push(h('div', null, ' 户型结构:' + (householdStructure || "无") + ''));
          arr.push(h('div', null, ' 房屋性质:' + (houseNature || "无") + ''));
          //提示是否重新核验
          this.$confirm(h('div', null, arr), '提示', {
            confirmButtonText: '重新核验',
            cancelButtonText: '取消',
            type: ''
          }).then(() => {
            //重新核验
            that.active = 2;
          }).catch(() => {
            //调用取消按钮事件，关闭弹框
            that.newclickCancel();
          });
        }
      }).finally(() => loading.close());
    },

    // 发起住建局房间核验方法
    getRecordHouseAutoCheck() {
      let that = this;
      //获取核验文件uuid数组
      const {fileUuidList} = that;
      //解构数据
      const {
        rightHolderName, warrantNo, certificateType, rightHolderID, phone, warrantType, rightType,
        rightHolderType
      } = that.rightHolderInfoVo;
      const {
        location, brokerID, brokerName, intermediaryName, businessType, applyStatus, businessNo,
      } = that.houseReqDataVo;

      const {comment} = that.cdata;
      const {apartmentUuid} = that.currentHouse;
      const rightHolderInfoVo = {
        rightHolderName, warrantNo, certificateType, rightHolderID, phone, warrantType, rightType,
        rightHolderType
      }
      const houseReqDataVo = {
        location, brokerID, brokerName, intermediaryName, businessType, applyStatus, businessNo,
      }
      //打包数据
      const data = {apartmentUuid, comment, rightHolderInfoVo, fileUuidList, houseReqDataVo, warrantNo}

      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //调用接口发起住建局房间核验  warrantNo
      getRecordHouseAutoCheck(data).then(res => {
        //解构返回数据
        const {msg} = res;
        //字符串转对象后解构返回参数
        const {result} = JSON.parse(msg);
        //解构返回状态码，返回信息，提示信息
        let {statusCode, statusMsg, businessStatus} = result;
        //转换code为数字
        statusCode = Number(statusCode);
        //判断statusCode是否大于0，大于0表示报错
        if (statusCode > 0) {
          //展示返回信息
          that.msg = statusMsg;
        } else {
          //展示提示信息
          MessageSuccess(businessStatus);
          //调用取消按钮事件，关闭弹框
          that.newclickCancel();
        }
      }).finally(() => loading.close());
    },

    //点击查询核验结果事件
    getRecordArtiCheckResult() {
      let that = this;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //获取房间uuid
      const {apartmentUuid: uuid} = that.currentHouse;
      //调用查询核验结果
      getRecordArtiCheckResult({uuid}).then(res => {
        //解构返回数据
        const {msg} = res;
        //字符串转对象后解构返回参数
        const {result} = JSON.parse(msg);
        //解构返回状态码
        let {statusCode, businessStatus,} = result;
        //转换code为数字
        statusCode = Number(statusCode);
        //判断statusCode是否大于0，大于0表示报错
        if (statusCode > 0) {
          MessageError(businessStatus);
        } else {
          MessageSuccess(businessStatus);
        }
      }).finally(() => loading.close());
    },

    //核验图片上传成功回调
    handleUploadSuccess({info}, index) {
      // 上传图片
      this.fileUuidList[index].scanData = info.uuid;
    },

    //添加核验文件方法
    addFile() {
      this.fileUuidList.push({scanName: null, scanData: null});
    },

    //移除核验文件方法
    removeFile(index) {
      this.fileUuidList.splice(index, 1);
    }
  },
  watch: {
    // 监听message属性的变化
    active: {
      handler(newValue, oldValue) {
        if(newValue === 2 ){
          if(this.ruleForm.resource ===  '房间核验')  this.footerBtnNameList = ['取消','提交']
          if(this.ruleForm.resource ===  '核验码核验')this.footerBtnNameList = ['取消','核验信息']
        }
        if(newValue === 1 ){
          if(this.ruleForm.resource ===  '房间核验')this.footerBtnNameList = ['取消','核验信息']
          if(this.ruleForm.resource ===  '核验码核验')this.footerBtnNameList = ['取消','核验信息']
        }
        console.log(`计数器从 ${oldValue} 变为 ${newValue}`);
      },
      immediate: true // 立即执行一次监听函数
    },

  }
}
</script>

<style scoped>

</style>
